import { zodResolver } from '@hookform/resolvers/zod';
import { liteLevels, statuses } from 'constants/statuses';
import { useForm } from 'react-hook-form';
import {
  TextInput,
  Select,
  MultiSelect,
  NumberInput,
  Switch,
  ColorInput,
  FileInput,
} from 'react-hook-form-mantine';
import * as M from '@mantine/core';
import {
  LAT_LONG_VALIDATOR,
  SCHOOL_ID_VALIDATOR,
} from 'utils/hasTextValidator';
import { DOMAIN_VALIDATOR } from 'utils/urlValidator';
import { z } from 'zod';
import { useCreateSchool, useDistricts, useUpdateSchool } from './queries';
import { states } from 'constants/states';
import { ModalProps, rem } from '@mantine/core';
import { useState } from 'react';
import { IconUpload } from '@tabler/icons-react';
import { CamelCasedPropertiesDeep } from 'type-fest';
import { ApiTypes } from 'types';
import { colors } from 'constants/colors';

export const EditSchoolModal = ({
  modalProps,
  schoolToEdit,
}: {
  modalProps: ModalProps;
  /** undefined == mode "create" */
  schoolToEdit?: CamelCasedPropertiesDeep<ApiTypes['SchoolViewExtra']>;
}) => {
  const { data: districts } = useDistricts();

  const schoolSchema = z.object({
    schoolName: z
      .string()
      .refine((val) => SCHOOL_ID_VALIDATOR(val), 'Enter a valid school code'),
    schoolTitle: z.string().min(1, 'Enter a school title'),
    nickname: z.string().optional(),
    website: z.string().url().optional(),
    ncesCode: z.string().optional(),
    domains: z
      .array(z.string())
      .refine((val) => DOMAIN_VALIDATOR(val), 'Enter only valid domains'),
    studentsCount: z.number().int().min(0).default(0),
    private: z.boolean().default(false),
    status: z.enum(['live', 'waitlist', 'suspended']),
    logo: z.any(),
    primaryColor: z.string(),
    liteLevel: z.enum(['L1', 'L2', 'L3']),

    // Step 2

    state: z.string().min(1),
    districtCode: z
      .string()
      .optional()
      .refine((val) =>
        val ? districts?.some((d) => d.districtCode === val) : true,
      ),

    // TODO: address field is in the old modal, but it's not expected by the backend?
    lat: z
      .number()
      .optional()
      .refine((val) => LAT_LONG_VALIDATOR(val), 'Enter a valid latitude value'),
    lng: z
      .number()
      .optional()
      .refine(
        (val) => LAT_LONG_VALIDATOR(val),
        'Enter a valid longitude value',
      ),
    timezone: z.string().optional(),
  });

  const { control, reset, handleSubmit } = useForm<
    z.infer<typeof schoolSchema>
  >({
    resolver: zodResolver(schoolSchema, { async: true }),
    resetOptions: { keepValues: false },
    defaultValues: {
      studentsCount: 0,
      domains: [],
    },
    values: schoolToEdit
      ? ({
          ...schoolToEdit,
          status: schoolToEdit.status as z.infer<typeof schoolSchema>['status'],
          studentsCount: schoolToEdit.studentsCount ?? 0,
          private: !!schoolToEdit.private,
          primaryColor: schoolToEdit.primaryColor ?? '',
          domains: schoolToEdit.domains as unknown as string[],
          liteLevel: schoolToEdit.liteLevel as z.infer<
            typeof schoolSchema
          >['liteLevel'],
        } satisfies z.infer<typeof schoolSchema>)
      : undefined,
  });

  const createSchoolMutation = useCreateSchool();
  const updateSchoolMutation = useUpdateSchool();

  const [createdDomains, setCreatedDomains] = useState<string[]>(
    (schoolToEdit?.domains as unknown as string[] | undefined) ?? [],
  );

  const onClose = () => {
    modalProps.onClose();
    reset({
      studentsCount: 0,
      domains: [],
    });
  };

  const onSubmit = (formData: z.infer<typeof schoolSchema>) => {
    const mutation = schoolToEdit ? updateSchoolMutation : createSchoolMutation;

    return mutation.mutateAsync({
      school: {
        ...formData,
        // the generated type is clearly wrong
        domains: formData.domains as any,
      },
      logo: formData.logo as File | undefined,
    });
  };

  return (
    <M.Modal
      {...modalProps}
      onClose={onClose}
      closeOnEscape={false}
      closeOnClickOutside={false}
      title={schoolToEdit ? 'Edit school' : 'Create school'}
      size="xl"
    >
      <M.Stack>
        <TextInput control={control} name="schoolName" label="School ID" />
        <TextInput control={control} name="schoolTitle" label="School Title" />
        <TextInput control={control} name="nickname" label="Nickname" />
        <TextInput control={control} name="website" label="Website" />
        <TextInput control={control} name="ncesCode" label="NCES Code" />
        <MultiSelect
          control={control}
          name="domains"
          label="Domains"
          creatable
          searchable
          data={createdDomains}
          getCreateLabel={(query) => (
            <>
              Add <b>{query}</b>
            </>
          )}
          onCreate={(query) => {
            setCreatedDomains((x) => [...x, query]);
            return query;
          }}
        />
        <NumberInput
          control={control}
          name="studentsCount"
          label="Enrollment"
        />
        <Switch control={control} name="private" label="Private school" />
        <Select
          control={control}
          name="status"
          label="School status"
          data={statuses.map((s) => ({ value: s.id, label: s.name }))}
        />

        {/* TODO: logo upload field here */}
        <FileInput
          control={control}
          name="logo"
          label="Upload logo"
          accept="image/png,image/jpeg"
          icon={<IconUpload size={rem(14)} />}
        />

        <ColorInput
          control={control}
          name="primaryColor"
          placeholder="Pick color"
          disallowInput
          rightSection={<></>}
          withPicker={false}
          swatches={Object.keys(colors)}
        />

        <Select
          control={control}
          name="liteLevel"
          label="Lite level"
          data={liteLevels.map((ll) => ({ value: ll.id, label: ll.name }))}
        />

        {/* Step 2 */}

        <Select
          control={control}
          name="state"
          label="State"
          searchable
          data={Object.entries(states).map(([value, key]) => ({
            value,
            label: key,
          }))}
        />

        <Select
          control={control}
          name="districtCode"
          label="District"
          searchable
          limit={50}
          data={
            districts?.map((d) => ({
              value: d.districtCode,
              label: d.districtName,
            })) ?? []
          }
        />

        <NumberInput control={control} name="lat" label="Latitude" />
        <NumberInput control={control} name="lng" label="Longitude" />
        <TextInput control={control} name="timezone" label="Timezone" />

        <M.Flex justify="flex-end" mt="xl" gap="sm">
          <M.Button onClick={onClose} variant="outline">
            Cancel
          </M.Button>
          <M.Button
            onClick={handleSubmit(async (formData) => {
              await onSubmit(formData);
              onClose();
            })}
            type="submit"
            loading={
              createSchoolMutation.isLoading || updateSchoolMutation.isLoading
            }
          >
            Save
          </M.Button>
        </M.Flex>
      </M.Stack>
    </M.Modal>
  );
};
