import { put, call, takeLatest, select } from 'redux-saga/effects';
import networkService from 'services/network.service';
import { ActionTypes } from '../actions/network';
import { hasAccessTo } from '../selectors/auth';

function* getNetworkStats() {
  const state = yield select();
  if (!hasAccessTo(state.auth.scopes, ['owner'])) {
    return;
  }
  try {
    const response = yield call(networkService().getNetworkStats);
    if (response.status === 200) {
      const data = response.data;

      yield put({
        type: ActionTypes.GET_NETWORK_STATS_SUCCESS,
        stats: data,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_NETWORK_STATS_FAIL, error });
  }
}

function* getMissingSchedules({ live }) {
  try {
    const response = yield call(
      networkService().getMissingResources,
      'schedules',
      null,
      { live },
    );
    if (response.status === 200) {
      const data = response.data;

      yield put({
        type: ActionTypes.GET_MISSING_SCHEDULES_SUCCESS,
        missing_schedules: data,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_MISSING_SCHEDULES_FAIL, error });
  }
}

function* getMissingScheduleNames() {
  try {
    const response = yield call(
      networkService().getMissingResources,
      'missing_schedule_names',
    );
    if (response.status === 200) {
      const data = response.data;

      yield put({
        type: ActionTypes.GET_MISSING_SCHEDULE_NAMES_SUCCESS,
        missing_schedule_names: data,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_MISSING_SCHEDULE_NAMES_FAIL, error });
  }
}

export default [
  takeLatest(ActionTypes.GET_NETWORK_STATS, getNetworkStats),
  takeLatest(ActionTypes.GET_MISSING_SCHEDULES, getMissingSchedules),
  takeLatest(ActionTypes.GET_MISSING_SCHEDULE_NAMES, getMissingScheduleNames),
];
