const ActionTypes = {
  GET_NETWORK_STATS: 'Network.GET_NETWORK_STATS',
  GET_NETWORK_STATS_SUCCESS: 'Network.GET_NETWORK_STATS_SUCCESS',
  GET_NETWORK_STATS_FAIL: 'Network.GET_NETWORK_STATS_FAIL',
  GET_MISSING_SCHEDULES: 'Network.GET_MISSING_SCHEDULES',
  GET_MISSING_SCHEDULES_SUCCESS: 'Network.GET_MISSING_SCHEDULES_SUCCESS',
  GET_MISSING_SCHEDULES_FAIL: 'Network.GET_MISSING_SCHEDULES_FAIL',
  GET_MISSING_SCHEDULE_NAMES: 'Network.GET_MISSING_SCHEDULE_NAMES',
  GET_MISSING_SCHEDULE_NAMES_SUCCESS:
    'Network.GET_MISSING_SCHEDULE_NAMES_SUCCESS',
  GET_MISSING_SCHEDULE_NAMES_FAIL: 'Network.GET_MISSING_SCHEDULE_NAMES_FAIL',
};

const getNetworkStats = () => ({
  type: ActionTypes.GET_NETWORK_STATS,
});

const getMissingSchedules = (live) => ({
  type: ActionTypes.GET_MISSING_SCHEDULES,
  live,
});

const getMissingScheduleNames = () => ({
  type: ActionTypes.GET_MISSING_SCHEDULE_NAMES,
});

const actions = {
  getNetworkStats,
  getMissingSchedules,
  getMissingScheduleNames,
};

export { ActionTypes, actions };
