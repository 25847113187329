import React from 'react';

import { DOMAIN_VALIDATOR as DOMAIN_LIST_VALIDATOR } from 'utils/urlValidator';
import {
  HAS_TEXT_VALIDATOR,
  SCHOOL_ID_VALIDATOR,
  VALID_NUMBER,
  LAT_LONG_VALIDATOR,
  VALID_OPTION,
  URL_VALIDATOR,
} from 'utils/hasTextValidator';
import { statuses, liteLevels } from 'constants/statuses';
import { states } from 'constants/states';
import miscService from 'services/misc.service';

export const SchoolDetailsStep = () => ({
  name: 'School Info',
  fields: [
    {
      type: 'SingleTextField',
      name: 'School ID',
      prop: 'school_name',
      currentValue: '',
      defaultValue: '',
      validators: [SCHOOL_ID_VALIDATOR],
      invalidText: 'Enter a valid school code.',
      valid: false,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
    {
      type: 'SingleTextField',
      name: 'School Title',
      prop: 'school_title',
      currentValue: '',
      defaultValue: '',
      validators: [HAS_TEXT_VALIDATOR],
      invalidText: 'Enter a school name',
      valid: false,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
    {
      type: 'SingleTextField',
      name: 'Nickname',
      prop: 'nickname',
      currentValue: '',
      defaultValue: '',
      validators: [],
      valid: true,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
    {
      type: 'SingleTextField',
      name: 'Website',
      prop: 'website',
      currentValue: '',
      defaultValue: '',
      validators: [URL_VALIDATOR],
      invalidText: 'Enter a valid URL, please include http/https.',
      valid: true,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
    {
      type: 'SingleTextField',
      name: 'NCES Code',
      prop: 'nces_code',
      currentValue: '',
      defaultValue: '',
      validators: [],
      valid: true,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
    {
      type: 'TagInputField',
      name: 'School Domain',
      prop: 'domains',
      currentValue: [],
      defaultValue: [],
      placeholder: 'Add domains separated by comma or enter',
      validators: [DOMAIN_LIST_VALIDATOR],
      invalidText: 'Enter only valid domains.',
      valid: false,
      isShowLabel: false,
    },
    {
      type: 'NumericField',
      name: 'Enrollment',
      prop: 'students_count',
      currentValue: 0,
      defaultValue: 0,
      min: 0,
      validators: [VALID_NUMBER],
      invalidText: 'Please set a valid number of enrolled students',
      valid: true,
      isShowLabel: true,
    },
    {
      type: 'SwitchField',
      name: 'Private school',
      prop: 'private',
      currentValue: false,
      defaultValue: false,
      valid: true,
      isShowLabel: true,
    },
    {
      type: 'SelectField',
      name: 'School Status',
      prop: 'status',
      currentValue: 0,
      defaultValue: 0,
      options: statuses,
      validators: [VALID_OPTION],
      invalidText: 'Select an option',
      valid: false,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
    {
      type: 'LogoUploadField',
      name: 'Sports Logo',
      prop: 'logo_id',
      currentLogo: null,
      defaultValue: null,
      currentValue: null,
      validators: [],
      valid: true,
      isShowLabel: true,
    },
    {
      type: 'ColorPickerField',
      name: 'Primary Color',
      prop: 'primary_color',
      currentValue: null,
      defaultValue: null,
      validators: [],
      invalidText: 'Select a color',
      valid: true,
      isShowLabel: false,
    },
    {
      type: 'SelectField',
      name: 'Lite Level',
      prop: 'lite_level',
      currentValue: 'L1',
      defaultValue: 'L1',
      options: liteLevels,
      invalidText: 'Select an option',
      valid: false,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
  ],
});

export const SchoolLocationStep = () => ({
  name: 'School Location',
  fields: [
    {
      type: 'SelectField',
      name: 'State',
      prop: 'state',
      currentValue: 0,
      defaultValue: 0,
      options: Object.keys(states).map((state_code) => ({
        id: state_code,
        name: states[state_code],
      })),
      valid: false,
      validators: [HAS_TEXT_VALIDATOR],
      isShowLabel: false,
      isPlaceholderUp: true,
    },
    {
      type: 'XHRSelectField',
      name: 'District',
      prop: 'district_code',
      currentValue: 0,
      defaultValue: 0,
      fetcher: (inputValue, resolve) => {
        miscService()
          .getDistricts({ search: inputValue })
          .then(({ data }) => {
            data = data.map((i) => {
              return { value: i.district_code, label: i.district_name };
            });
            resolve(data);
          });
      },
      optionFormatter: ({ value, label }) => {
        return (
          <div>
            <div>{label}</div>
            <div className="district-id">
              <span>{value}</span>
            </div>
          </div>
        );
      },
      validators: [VALID_OPTION],
      invalidText: 'Select an option',
      valid: false,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
    {
      type: 'GoogleAutoCompleteField',
      name: 'School Address',
      prop: 'address',
      currentValue: '',
      defaultValue: '',
      invalidText: 'Enter the school address.',
      valid: true,
      isShowLabel: false,
      isGooglePlace: true,
      isPlaceholderUp: true,
    },
    {
      type: 'SingleTextField',
      name: 'Latitude',
      prop: 'lat',
      currentValue: '',
      defaultValue: '',
      validators: [LAT_LONG_VALIDATOR],
      invalidText: 'Enter a valid latitude value.',
      valid: false,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
    {
      type: 'SingleTextField',
      name: 'Longitude',
      prop: 'lng',
      currentValue: '',
      defaultValue: '',
      validators: [LAT_LONG_VALIDATOR],
      invalidText: 'Enter a valid longitude value.',
      valid: false,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
    {
      type: 'SingleTextField',
      name: 'Timezone',
      prop: 'timezone',
      currentValue: '',
      defaultValue: '',
      validators: [HAS_TEXT_VALIDATOR], // TODO should validate against acceptable tz list
      invalidText: 'Enter a valid timezone value.',
      valid: false,
      isShowLabel: false,
      isPlaceholderUp: true,
    },
  ],
});
