import React from 'react';
import { Modal } from 'react-bootstrap';
import { Axios, AxiosError } from 'axios';

import StepContainer from 'components/shared/Modal/StepContainer/StepContainer';
import schoolsService from 'services/schools.service';
import './CreateSchoolModal.scss';
import {
  SchoolDetailsStep,
  SchoolLocationStep,
} from './Steps/CreateSchoolSteps';

const CreateSchoolModal = ({ hide }) => {
  const modalData = {
    name: 'School Setup',
    steps: [SchoolDetailsStep(), SchoolLocationStep()],
    onSubmit: (form) => {
      if (form.school_name && form.school_title && form.domains) {
        return schoolsService().createSchool({
          ...form,
          website: form.website !== '' ? form.website : null,
          nces_code: form.nces_code !== '' ? form.nces_code : null,
          lite_level: form.lite_level,
        });
      } else {
        return new Promise((res, reject) => reject('Missing fields'));
      }
    },
    getSuccessText: (result) => `Created ${result.data.school_title}`,
    getErrorText: (err) => {
      const baseMsg = 'An error occurred when creating the school';

      if (err instanceof AxiosError) {
        const errors = err.response?.data?.detail
          ?.map((errItem) => errItem.msg)
          .filter((msg) => !!msg)
          .join(', ');

        if (errors) {
          return `${baseMsg}: ${errors}`;
        }
      }

      return baseMsg;
    },
  };

  return (
    <Modal show className="create-school" onHide={hide}>
      <StepContainer isToggleHeaderActive modalData={modalData} close={hide} />
    </Modal>
  );
};

export default CreateSchoolModal;
